import React from 'react';
import './Servicos.css';

const Servicos = () => {
    return (
        <section id="servicos" className='servicos'>
            <h2>Nossos Serviços:</h2>
            <ul>
                <li><strong>Assessoria Tributária:</strong> Análise e planejamento tributário, auxiliando empresas a otimizar sua carga tributária e garantir conformidade legal.</li>
                <li><strong>Consultoria Empresarial:</strong> Orientação jurídica para negócios, abrangendo desde a constituição até a gestão de contratos e questões regulatórias.</li>
                <li><strong>Direito Trabalhista:</strong> Atuação em litígios e consultoria preventiva, assegurando que empresas e colaboradores estejam em conformidade com a legislação trabalhista.</li>
            </ul>
        </section>
    );
};

export default Servicos;
