import React from 'react';

const Equipe = () => {
  return (
    <section id="equipe">
      <h2>Equipe</h2>
      <p>Conteúdo sobre a equipe...</p>
    </section>
  );
};

export default Equipe;
