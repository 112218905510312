// import { NavLink } from 'react-router-dom';
import React, { useState } from 'react';
import "./Cabecalho.css";
import logo from "../assets/logoIA.png";

const Cabecalho = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <header className="header">
            <div className="logo">
                <img src={logo} alt="logo" />
            </div>
            {!isMenuOpen && (
                <div className="titulo-base">
                    <h1>Sergio David | Advocacia</h1>
                </div>
            )}
            <div className="hamburger" onClick={toggleMenu}>
                <div className={`line ${isMenuOpen ? 'open' : ''}`}></div>
                <div className={`line ${isMenuOpen ? 'open' : ''}`}></div>
                <div className={`line ${isMenuOpen ? 'open' : ''}`}></div>
            </div>
            <nav className={`navigation ${isMenuOpen ? 'open' : ''}`}>
                <ul>
                    <li onClick={() => scrollToSection('sobre')}>Sobre Nós</li>
                    <li onClick={() => scrollToSection('servicos')}>Serviços</li>
                    <li onClick={() => scrollToSection('equipe')}>Equipe</li>
                    <li onClick={() => scrollToSection('noticias')}>Noticias</li>
                    <li onClick={() => scrollToSection('contato')}>Contato</li>
                </ul>
            </nav>
        </header>
    );
};

export default Cabecalho;