import React from 'react'
import Cabecalho from '../components/Cabecalho';
import Sobre from '../components/Sobre';
import Servicos from '../components/Servicos';
import Equipe from '../components/Equipe';
import Contato from '../components/Contato';
import Noticias from '../components/Noticias';


function PaginaInicial() {
  return (
    <div>
      <Cabecalho />
      <main className="conteudo">
        <Sobre />
        <Servicos />
        <Equipe />
        <Noticias/>
        <Contato />
      </main>
      <a href="https://wa.me/5567998300275" class="whatsapp-icon" target="_blank">
        <img src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg" alt="WhatsApp" />
      </a>
    </div>
  )
}

export default PaginaInicial