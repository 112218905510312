import React from 'react';
import './Sobre.css';

const Sobre = () => {
    return (
        <section id="sobre" className="sobre">
            <h2>Sobre Nós</h2>
            <p className='texto-principal'>
                No Sergio David Advocacia, somos especializados na prestação de serviços jurídicos em contencioso e consultoria preventiva. Atuamos nas áreas tributária, empresarial e trabalhista, oferecendo soluções jurídicas que atendem às necessidades específicas de cada cliente.

                Nossa equipe é composta por profissionais experientes e comprometidos, que buscam não apenas resolver conflitos, mas também prevenir problemas legais, garantindo a tranquilidade e a segurança jurídica de nossos clientes. Valorizamos um atendimento personalizado, entendendo que cada caso é único e merece uma abordagem cuidadosa e estratégica.
            </p>

            <p className="missao">
                <strong>Nossa Missão:</strong> é proteger os interesses de nossos clientes, proporcionando segurança jurídica e orientações precisas para a tomada de decisões estratégicas. Acreditamos que a prevenção é a melhor forma de evitar litígios, e, por isso, trabalhamos lado a lado com nossos clientes, orientando-os em todos os aspectos legais de seus negócios e relações profissionais.
            </p>
            <p className="missao">
                Seja na atuação em litígios ou na consultoria preventiva, estamos aqui para apoiar você e sua empresa em cada passo do caminho, construindo parcerias duradouras baseadas em confiança e transparência.
            </p>




        </section>
    );
};

export default Sobre;