import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Slider from 'react-slick';
import './Noticias.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Noticias = () => {
  const [noticias, setNoticias] = useState([]);

  useEffect(() => {
    const fetchNoticias = async () => {
      try {
        const response = await axios.get('https://sergiodavid.adv.br/rss.php');
        const parser = new window.DOMParser();
        const xml = parser.parseFromString(response.data, 'text/xml');

        const items = Array.from(xml.getElementsByTagName('item')).map(item => {
          const description = item.getElementsByTagName('description')[0].textContent;

          const imgMatch = description.match(/<img[^>]+src="([^">]+)"/);
          const imgSrc = imgMatch ? imgMatch[1] : '';

          return {
            title: item.getElementsByTagName('title')[0].textContent,
            link: item.getElementsByTagName('link')[0].textContent,
            description: description.replace(/<[^>]*>/g, ''),
            imgSrc,
          };
        });

        setNoticias(items);
      } catch (error) {
        console.error('Erro ao buscar notícias:', error);
      }
    };

    fetchNoticias();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 250,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
    ]
  };

  return (
    <section id="noticias">
      <div className="noticias-carrossel">
        <h2>Notícias Jurídicas</h2>
        <Slider {...settings}>
          {noticias.map((noticia, index) => (
            <div key={index} className="noticia-card">
              {noticia.imgSrc && (
                <div className="noticia-image">
                  <img src={noticia.imgSrc} alt={noticia.title} />
                  <div className="noticia-text">
                    <h3>{noticia.title}</h3>
                    <p>{noticia.description}</p>
                    <a href={noticia.link} target="_blank" rel="noopener noreferrer">Leia mais</a>
                  </div>
                </div>
              )}
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default Noticias;
