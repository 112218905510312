import React from 'react';
import './Contato.css'; 

const Contato = () => {
  return (
    <section id="contato">
      <h2>Contato</h2>
      <p>Formulário ou informações de contato...</p>
    </section>
  );
};

export default Contato;
